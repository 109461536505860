import { toast } from 'sonner';

import { Button } from '@/components/ui/button';
import { useTranslation } from '@/i18n/client';
import { Eat_RestaurantFragment } from '@/lib/__generated__/graphql';
import { useNavigate } from '@/lib/navigation';

type Props = {
  restaurant: Eat_RestaurantFragment;
  tableNumber: string | null;
};

export function ViewMenuButton(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button
      className="w-full bg-laaxred hover:bg-laaxreddark"
      onClick={() => navigate(`/${props.restaurant.id}/menu`)}
    >
      {t('Home - View menu button', 'View the menu')}
    </Button>
  );
}
