import { ArrowRightIcon } from 'lucide-react';

type Props = {
  onClick?: () => void;
  label: string;
  chargeText?: string;
  hasSubLocations?: boolean;
};

export function DeliveryLocationRow(props: Props) {
  return (
    <li
      className="flex cursor-pointer items-center justify-between border-b border-gray-200 py-3"
      onClick={props.onClick}
    >
      <span className="text-base">{props.label}</span>
      <div className="flex items-center">
        {props.chargeText && (
          <span className="mr-2 text-sm text-gray-500">{props.chargeText}</span>
        )}
        {props.hasSubLocations && (
          <ArrowRightIcon className="size-4 text-action-blue" />
        )}
      </div>
    </li>
  );
}
