import React, { useCallback } from 'react';
import { toast } from 'sonner';

import { PhoneNumberInput } from '@/app/4-order-details/components/PhoneNumberInput';
import { CommentsInput } from '@/app/4-order-details/components/comments-input';
import { DeliveryLocationsBlock } from '@/app/4-order-details/components/delivery-locations-block';
import { NameInput } from '@/app/4-order-details/components/name-input';
import { PushPermissionBlock } from '@/app/4-order-details/components/push-permission-block';
import { TableNumberInput } from '@/app/4-order-details/components/table-number-input';
import { validatePhoneNumber } from '@/app/4-order-details/utils/validate-phone-number';
import { OrderFooter } from '@/components/common/order-footer';
import { useTranslation } from '@/i18n/client';
import { useNavigate, useParamsRequired } from '@/lib/navigation';
import { useDerivedDeliveryValues, useMainStore } from '@/stores/main-store';
import { usePushPermissionStore } from '@/stores/push-permission-store';

const formNames = {
  name: 'name',
  tableNumber: 'tableNumber',
  phone: 'phone',
  comment: 'comment',
};

export function OrderDetailsPage() {
  const { t } = useTranslation();
  const { restaurantId } = useParamsRequired('restaurantId');

  const {
    cart,
    menu,
    restaurant,
    nameOnReceipt,
    tableNumber,
    setTableNumber,
    setPhoneNumber,
  } = useMainStore((state) => ({
    cart: state.cart,
    menu: state.menu,
    restaurant: state.restaurant,
    nameOnReceipt: state.nameOnReceipt,
    tableNumber: state.tableNumber,
    setTableNumber: state.setTableNumber,
    setPhoneNumber: state.setPhoneNumber,
  }));

  const { isDeliveryOptionSelected } = useDerivedDeliveryValues();

  const { pushAvailable, pushPermissionGranted } = usePushPermissionStore();

  const navigate = useNavigate();

  const validateTableNumber = useCallback(() => {
    if (
      tableNumber &&
      restaurant?.tableNumbers &&
      !restaurant.tableNumbers.includes(tableNumber)
    ) {
      toast.error(
        t(
          'Details - Not a valid table number',
          'Sorry, that’s not a valid table number for {{restaurantName}}',
          { restaurantName: restaurant.name },
        ),
        { duration: 3000 },
      );
      setTableNumber('');
      return false;
    }
    return true;
  }, [tableNumber]);

  const onPhoneBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const result = await validatePhoneNumber(e.currentTarget.value);
    if (result && result.isValid()) {
      return setPhoneNumber(result.format('E.164'));
    }
    toast.error(
      t(
        'Details - Not a valid phone number',
        'Phone number is not valid, try starting with country code',
      ),
      { duration: 3000 },
    );
  };

  if (menu === null || cart === null) {
    return null;
  }

  return (
    <form
      onSubmit={async (ev) => {
        ev.preventDefault();
        const phoneInput = ev.currentTarget.elements.namedItem(
          formNames.phone,
        ) as HTMLInputElement;

        if (phoneInput) {
          const phoneNumber = await validatePhoneNumber(phoneInput.value);
          if (!phoneNumber || !phoneNumber.isValid()) {
            phoneInput.setCustomValidity('Invalid phone number');
            phoneInput.reportValidity();
            return;
          }
          setPhoneNumber(phoneNumber.format('E.164'));
        }

        if (restaurant?.diningMode === 'TABLE' && !validateTableNumber()) {
          return;
        }

        if (!nameOnReceipt) {
          return;
        }

        navigate(`/${restaurantId}/checkout`);
      }}
    >
      <main className="mt-20 flex flex-col gap-6 px-5">
        <p className="text-md">
          {t(
            'Details - Title',
            'This information will help you get your order to the right place.',
          )}
        </p>

        <div className="mb-6 flex flex-col gap-3">
          {restaurant?.diningMode === 'TABLE' && (
            <TableNumberInput validateTableNumber={validateTableNumber} />
          )}

          {restaurant?.diningMode === 'DELIVERY' && <DeliveryLocationsBlock />}

          <NameInput />

          {restaurant?.diningMode !== 'TABLE' && (
            <PhoneNumberInput onBlur={onPhoneBlur} />
          )}

          {restaurant?.diningMode !== 'TABLE' && pushAvailable && (
            <PushPermissionBlock />
          )}

          <CommentsInput restaurantId={restaurantId} />
        </div>
      </main>

      <OrderFooter
        buttonText={t('Cart summary - Button - At details page', 'To checkout')}
        stepNumber={3}
        onButtonClick={() => {}}
        buttonDisabled={
          (restaurant?.diningMode !== 'TABLE' &&
            pushAvailable &&
            !pushPermissionGranted) ||
          (restaurant?.diningMode === 'DELIVERY' && !isDeliveryOptionSelected)
        }
      />
    </form>
  );
}
