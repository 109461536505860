import { useTranslation } from 'react-i18next';

import { PaymentMethodFragment } from '@/lib/__generated__/graphql';
import { cn } from '@/lib/utils';
import {
  formatPaymentMethodExpiry,
  isPaymentMethodExpired,
} from '@/stores/payment-store';

export function PaymentMethodInfo(props: {
  paymentMethod: PaymentMethodFragment;
}) {
  const { t } = useTranslation();
  const isExpired = isPaymentMethodExpired(props.paymentMethod);
  const expiryInfo = formatPaymentMethodExpiry(props.paymentMethod);

  return (
    <div className="flex flex-col items-start">
      <p className={cn(expiryInfo && 'text-sm')}>
        {props.paymentMethod.maskedNumber === 'TWINT'
          ? t(`Payment - Saved Payment method`, 'Your saved TWINT')
          : props.paymentMethod.maskedNumber}
      </p>
      <div className="flex flex-row gap-3">
        {expiryInfo && (
          <p className="text-xs text-muted-foreground">{expiryInfo}</p>
        )}
        {isExpired && (
          <p className="text-xs text-red-600">
            {t('Payment - Card expired', 'Card expired')}
          </p>
        )}
      </div>
    </div>
  );
}
