import { PhoneIcon } from 'lucide-react';
import React from 'react';

import { IconInput } from '@/app/4-order-details/components/icon-input';
import { useTranslation } from '@/i18n/client';
import { useMainStore } from '@/stores/main-store';

type Props = {
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
};

export function PhoneNumberInput(props: Props) {
  const { t } = useTranslation();

  const { phoneNumber, setPhoneNumber } = useMainStore((state) => ({
    phoneNumber: state.phoneNumber,
    setPhoneNumber: state.setPhoneNumber,
  }));

  return (
    <IconInput
      name="phone"
      icon={<PhoneIcon className="mt-0.5 size-6" />}
      required
      type="tel"
      autoComplete="tel"
      inputMode="tel"
      value={phoneNumber ?? ''}
      onBlur={props.onBlur}
      onChange={(e) => setPhoneNumber(e.target.value)}
      placeholder={t('Details - Phone number placeholder', 'Phone number*')}
    />
  );
}
