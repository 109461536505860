import { t } from 'i18next';
import { LoaderCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { getCart } from '@/actions/cart/get-cart';
import { getOrder } from '@/actions/order/get-order';
import { Order } from '@/actions/order/order-types';
import { CheckoutDoneButton } from '@/app/5-checkout/3-done/components/checkout-done-button';
import { OrderInfo } from '@/app/shared-components/order-info';
import { FadeFromBelow } from '@/components/common/fade-from-below';
import { OrderWaiter } from '@/components/common/icons/order-waiter';
import { Eat_Order_Status } from '@/lib/__generated__/graphql';
import { inAppLoadActiveOrders } from '@/lib/app-integration';
import { useMainStore } from '@/stores/main-store';
import { useLatestPaymentMethodAsDefault } from '@/stores/payment-store';

function useResetOrderState(restaurantId?: string | null) {
  useLatestPaymentMethodAsDefault();
  const {
    setCart,
    setCommentsForRestaurantId,
    setTableNumber,
    clearDeliverySelection,
  } = useMainStore();
  useEffect(() => {
    if (!restaurantId) {
      return;
    }

    // Cart should have been cleared by now. Fetch it again.
    getCart({ restaurantId }).then(setCart);

    // Clear comments, table number, and delivery selection, should only be used for the current order.
    setCommentsForRestaurantId('', restaurantId);
    setTableNumber('');
    clearDeliverySelection();
  }, [restaurantId]);
}

const PENDING_INTERVAL = 2000;
const IN_PREPARATION_INTERVAL = 10_000;

function useOrder(orderId: string | null): Order | null {
  const [order, setOrder] = useState<Order | null>(null);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    async function refreshOrder() {
      if (!orderId) return;

      const fetchedOrder = await getOrder({ orderId });

      inAppLoadActiveOrders();

      switch (fetchedOrder.__typename) {
        case 'Eat_Order_Delivery': {
          switch (fetchedOrder.deliveryOrderStatus) {
            case 'PAYMENT_PENDING':
            case 'ORDER_PENDING':
              timeout = setTimeout(refreshOrder, PENDING_INTERVAL);
              break;
            case 'ORDER_IN_PREPARATION':
            case 'ORDER_OUT_FOR_DELIVERY':
              timeout = setTimeout(refreshOrder, IN_PREPARATION_INTERVAL);
              break;
            default:
              break;
          }
          break;
        }

        case 'Eat_Order_SelfCheckout': {
          switch (fetchedOrder.selfCheckoutOrderStatus) {
            case 'PAYMENT_PENDING':
            case 'ORDER_PENDING':
              timeout = setTimeout(refreshOrder, PENDING_INTERVAL);
              break;
            default:
              break;
          }
          break;
        }

        case 'Eat_Order_Table': {
          switch (fetchedOrder.tableOrderStatus) {
            case 'PAYMENT_PENDING':
            case 'ORDER_PENDING':
              timeout = setTimeout(refreshOrder, PENDING_INTERVAL);
              break;
            case 'ORDER_IN_PREPARATION':
              timeout = setTimeout(refreshOrder, IN_PREPARATION_INTERVAL);
              break;
            default:
              break;
          }
          break;
        }

        case 'Eat_Order_TakeAway': {
          switch (fetchedOrder.takeAwayOrderStatus) {
            case 'PAYMENT_PENDING':
            case 'ORDER_PENDING':
              timeout = setTimeout(refreshOrder, PENDING_INTERVAL);
              break;
            case 'ORDER_IN_PREPARATION':
              timeout = setTimeout(refreshOrder, IN_PREPARATION_INTERVAL);
              break;
            default:
              break;
          }
          break;
        }
      }

      setOrder(fetchedOrder);
    }

    timeout = setTimeout(refreshOrder, 1000);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [orderId]);

  return order;
}

export function CheckoutDonePage() {
  const { restaurantId } = useParams();

  const restaurant = useMainStore((store) => store.restaurant);

  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  const order = useOrder(orderId);

  const orderStatus = order?.status ?? 'PENDING';

  useResetOrderState(order && restaurantId);

  if (!restaurant) {
    return null;
  }

  const subtitle = order && getOrderStatusSubtitle(order);

  return (
    <div className="mt-20 flex flex-col items-center gap-3 px-5">
      {order && <OrderStatusImage order={order} />}
      <FadeFromBelow
        className="mt-4 flex flex-col gap-1 text-center"
        key={orderStatus}
      >
        <h3 className="text-2xl font-bold">{titles[orderStatus]}</h3>
        {subtitle && (
          <p className="text-md mx-4 mt-2 leading-5 text-gray-600">
            {subtitle}
          </p>
        )}
      </FadeFromBelow>
      {order && orderStatus !== 'PENDING' ? (
        <>
          <FadeFromBelow delay={80} className="my-6 self-stretch">
            {/* <Link to={`/orders?expanded=${order.id}`}> */}
            <OrderInfo order={order} expanded />
            {/* </Link> */}
          </FadeFromBelow>
          <FadeFromBelow delay={160}>
            <CheckoutDoneButton restaurantId={restaurantId} />
          </FadeFromBelow>
        </>
      ) : (
        <LoaderCircle
          strokeWidth={1}
          className="my-6 h-20 w-20 animate-spin opacity-30"
        />
      )}
    </div>
  );
}

const titles: Record<Eat_Order_Status, string> = {
  SUCCESS: t('Checkout - Success - Title', 'Thank you!'),
  PENDING: t('Checkout - Pending - Title', 'Almost there...'),
  FAILURE: t('Checkout - Failure - Title', 'Your order failed.'),
};

function OrderStatusImage(props: { order: Order }) {
  switch (props.order.__typename) {
    case 'Eat_Order_Delivery':
    case 'Eat_Order_TakeAway': {
      return (
        <img
          id="waiter"
          alt=""
          src="/icons/bag.svg"
          className="my-6 h-24 object-contain"
        />
      );
    }

    case 'Eat_Order_Table': {
      switch (props.order.status) {
        case 'PENDING':
          return <OrderWaiter className="my-6 h-24" playAnimation />;
        case 'SUCCESS':
          return <OrderWaiter className="my-6 h-24" playAnimation={false} />;
        case 'FAILURE':
          return (
            <img
              id="waiter"
              src="/icons/waiter-error.svg"
              className="my-6 h-24 object-contain"
            />
          );
        default:
          return null;
      }
    }

    case 'Eat_Order_SelfCheckout':
    default:
      return null;
  }
}

function getOrderStatusSubtitle(order: Order) {
  switch (order.__typename) {
    case 'Eat_Order_Delivery': {
      switch (order.deliveryOrderStatus) {
        case 'PAYMENT_PENDING':
          return t(
            'Checkout - Generic - Payment pending - Description',
            'We are processing your payment, please wait a moment...',
          );
        case 'ORDER_PENDING':
          return t(
            'Checkout - Generic - Pending - Description',
            'Your order is on its way to the kitchen...',
          );
        case 'ORDER_FAILED':
          return t(
            'Checkout - Delivery - Failed - Description',
            'Something has gone wrong with your order. Please contact the restaurant or try again.',
          );
        case 'ORDER_IN_PREPARATION':
          return t(
            'Checkout - Delivery - In preparation - Description',
            'We are preparing your order. We’ll notify you when it’s on the way.',
          );
        case 'ORDER_OUT_FOR_DELIVERY':
          return t(
            'Checkout - Delivery - Ready for pickup - Description',
            'Your order is on the way.',
          );
        case 'ORDER_DONE':
          return t(
            'Checkout - Delivery - Delivered - Description',
            'Your order has been delivered.',
          );
        case 'PAYMENT_REFUNDED':
          return t(
            'Checkout - Generic - Payment refunded - Description',
            'Something went wrong with your order. You have not been charged.',
          );
        case 'PAYMENT_FAILED':
          return t(
            'Checkout - Generic - Payment failed - Description',
            'Something went wrong with your payment. You have not been charged.',
          );
        default:
          return null;
      }
    }

    case 'Eat_Order_Table': {
      switch (order.tableOrderStatus) {
        case 'PAYMENT_PENDING':
          return t(
            'Checkout - Generic - Payment pending - Description',
            'We are processing your payment, please wait a moment...',
          );
        case 'ORDER_PENDING':
          return t(
            'Checkout - Generic - Pending - Description',
            'Your order is on its way to the kitchen...',
          );
        case 'ORDER_FAILED':
          return t(
            'Checkout - Table - Failed - Description',
            'Something has gone wrong with your order. Please contact the restaurant staff or try again.',
          );
        case 'ORDER_IN_PREPARATION':
          return t(
            'Checkout - Table - In preparation - Description',
            'We are preparing your order.',
          );
        case 'ORDER_DONE':
          return t(
            'Checkout - Table - Done - Description',
            'Your order will be brought to your table as soon as it’s ready.',
          );
        case 'PAYMENT_REFUNDED':
          return t(
            'Checkout - Generic - Payment refunded - Description',
            'Something went wrong with your order. You have not been charged.',
          );
        case 'PAYMENT_FAILED':
          return t(
            'Checkout - Generic - Payment failed - Description',
            'Something went wrong with your payment. You have not been charged.',
          );
        default:
          return null;
      }
    }

    case 'Eat_Order_TakeAway': {
      switch (order.takeAwayOrderStatus) {
        case 'PAYMENT_PENDING':
          return t(
            'Checkout - Generic - Payment pending - Description',
            'We are processing your payment, please wait a moment...',
          );
        case 'ORDER_PENDING':
          return t(
            'Checkout - Generic - Pending - Description',
            'Your order is on its way to the kitchen...',
          );
        case 'ORDER_FAILED':
          return t(
            'Checkout - Take-away - Failed - Description',
            'Something has gone wrong with your order. Please contact the restaurant staff or try again.',
          );
        case 'ORDER_IN_PREPARATION':
          return t(
            'Checkout - Take-away - In preparation - Description',
            'We are preparing your order. We’ll notify you when it’s ready.',
          );
        case 'ORDER_READY_FOR_PICKUP':
          return t(
            'Checkout - Take-away - Ready for pickup - Description',
            'Your order is ready for collection.',
          );
        case 'ORDER_DONE':
          return t(
            'Checkout - Take-away - Done - Description',
            'Your order is complete.',
          );
        case 'PAYMENT_REFUNDED':
          return t(
            'Checkout - Generic - Payment refunded - Description',
            'Something went wrong with your order. You have not been charged.',
          );
        case 'PAYMENT_FAILED':
          return t(
            'Checkout - Generic - Payment failed - Description',
            'Something went wrong with your payment. You have not been charged.',
          );
        default:
          return null;
      }
    }

    default: {
      const unhandledOrderType = order as Order;

      switch (unhandledOrderType.status) {
        case 'PENDING':
          return t(
            'Checkout - Generic - Pending - Description',
            'Your order is on its way to the kitchen...',
          );
        case 'SUCCESS':
          return t(
            'Checkout - Generic - Success - Description',
            'Your order was successfully sent to the kitchen.',
          );
        case 'FAILURE':
          return t(
            'Checkout - Generic - Failure - Description',
            'Something went wrong with your order. Please contact the restaurant or try again.',
          );
        default:
          return null;
      }
    }
  }
}
