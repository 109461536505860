import { Auth0Client } from '@auth0/auth0-spa-js';
import { useEffect, useState } from 'react';

export const auth = window.isSecureContext
  ? new Auth0Client({
      domain: import.meta.env.VITE_PUBLIC_AUTH0_DOMAIN,
      clientId: import.meta.env.VITE_PUBLIC_AUTH0_CLIENT_ID,
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    })
  : // Auth0 client can only be instantiated in secure context because of web crypto.
    // On urls separate from hosting env or localhost, login won't anyway work because of auth-config.
    // We might as well mock the thing instead of forcing ssl dev servers.
    (new Proxy(
      {
        loginWithRedirect() {
          alert('Auth is disabled outside of https:// or localhost urls!');
          return Promise.resolve();
        },
      } as Record<any, any>,
      {
        get(target, property) {
          if (!(property in target)) {
            return () => Promise.resolve(false);
          }
          //@ts-expect-error Typescript does not handle this in v5
          return target[property];
        },
      },
    ) as Auth0Client);

// Fire and forget checkSession to make auth0 refresh current status
auth.checkSession();

declare global {
  interface Window {
    accessToken: string | undefined;
  }
}

// In case of web view, we use the existing app token which we will inject into the web view
function getInjectedAccessToken() {
  return window.accessToken ?? null;
}

export async function getAuthToken() {
  const isAuthenticated = await auth.isAuthenticated();
  try {
    const token = isAuthenticated ? await auth.getTokenSilently() : null;
    return token || getInjectedAccessToken();
  } catch {
    // Throw away the access token silently (without redirecting to the login page)
    auth.logout({ openUrl: false }).catch();
    return null;
  }
}

export async function checkIsAuthenticated() {
  const webViewToken = getInjectedAccessToken();
  if (webViewToken != null) return true;

  return auth.isAuthenticated();
}

export function useIsAuthenticated() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    checkIsAuthenticated().then((isAuthenticated) => {
      setIsAuthenticated(isAuthenticated);
    });
  }, []);

  return isAuthenticated;
}
