import { useState } from 'react';

import { PaymentBrandIcon } from '@/app/5-checkout/1-start/components/payment-brand-icon';
import { PaymentTypeName } from '@/app/5-checkout/1-start/components/payment-type-name';
import { PaymentTypeSelectItem } from '@/app/5-checkout/1-start/components/payment-type-select-item';
import { SavedPaymentMethodSelectItem } from '@/app/5-checkout/1-start/components/saved-payment-method-select-item';
import { Drawer, DrawerContent, DrawerTrigger } from '@/components/ui/drawer';
import { Switch } from '@/components/ui/switch';
import { useTranslation } from '@/i18n/client';
import { useIsAuthenticated } from '@/lib/auth';
import { isFeatureFlagEnabled } from '@/lib/featureFlags';
import {
  paymentTypes,
  usePayment,
  useSavedPaymentMethods,
} from '@/stores/payment-store';

import { PaymentMethodInfo } from '../components/payment-method-info';

export function CheckoutPaymentSection() {
  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated();

  const {
    selectedPaymentType,
    setSelectedPaymentTypeCode,
    selectedPaymentMethod,
    setSelectedPaymentMethodId,
    saveNewCreditCard,
    toggleSaveNewCreditCard,
  } = usePayment();

  const { savedPaymentMethods, supportedPaymentTypes } =
    useSavedPaymentMethods();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const canSavePaymentMethod =
    isAuthenticated &&
    (selectedPaymentType?.code === 'CREDIT_CARD' ||
      (selectedPaymentType?.code === 'TWINT' &&
        //TODO: Remove this when twint payments are confirmed working for everyone.
        isFeatureFlagEnabled('save-twint-payment')));

  const isOverwrite =
    canSavePaymentMethod &&
    selectedPaymentType.code === 'TWINT' &&
    savedPaymentMethods?.some((paymentType) => paymentType.brand === 'TWINT');

  return (
    <div className="flex flex-col gap-3 px-5 pb-5 pt-3">
      <h1 className="text-xl font-bold">
        {t('Checkout - Payment', 'Payment')}
      </h1>

      <div className="flex flex-col divide-y-1 divide-muted rounded-md border px-3 py-1">
        <div className="flex w-full items-center justify-between hover:cursor-pointer">
          <Drawer open={drawerOpen} onOpenChange={setDrawerOpen}>
            <DrawerTrigger asChild>
              {selectedPaymentType ? (
                <div className="flex flex-1 items-center py-3">
                  <div className="flex flex-1 items-center gap-3">
                    <img
                      src={selectedPaymentType.imageUrl}
                      alt={selectedPaymentType.untranslatedName}
                      className="h-6"
                    />
                    <p className="text-md shrink-0">
                      <PaymentTypeName paymentType={selectedPaymentType} />
                    </p>
                  </div>
                  <p className="text-sm text-action-blue">
                    {t('Checkout - Change payment', 'change')}
                  </p>
                </div>
              ) : selectedPaymentMethod ? (
                <div className="flex flex-1 items-center py-2">
                  <div className="flex flex-1 items-center gap-3">
                    <PaymentBrandIcon brand={selectedPaymentMethod.brand} />
                    <PaymentMethodInfo paymentMethod={selectedPaymentMethod} />
                    <p className="ml-auto text-sm text-action-blue">
                      {t('Checkout - Change payment', 'change')}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="flex flex-1">
                  <p className="text-md py-3 text-action-blue">
                    {t(
                      'Checkout - Select payment method',
                      'Select payment method',
                    )}
                  </p>
                </div>
              )}
            </DrawerTrigger>

            <DrawerContent className="px-3 pb-2">
              <div className="flex flex-col gap-0 divide-y-2 divide-muted pt-2">
                {(savedPaymentMethods?.length ?? 0) > 0 && (
                  <div className="divide-y-1 divide-muted">
                    {savedPaymentMethods?.map((savedPaymentMethod) => (
                      <SavedPaymentMethodSelectItem
                        key={savedPaymentMethod.id}
                        savedPaymentMethod={savedPaymentMethod}
                        selected={
                          selectedPaymentMethod?.id === savedPaymentMethod.id
                        }
                        onSelect={(paymentMethodId) => {
                          setSelectedPaymentMethodId(paymentMethodId);
                          setDrawerOpen(false);
                        }}
                      />
                    ))}
                  </div>
                )}
                <div className="divide-y-1 divide-muted">
                  {paymentTypes
                    .filter((type) => supportedPaymentTypes.includes(type.code))
                    .map((paymentType) => (
                      <PaymentTypeSelectItem
                        key={paymentType.code}
                        paymentType={paymentType}
                        selected={
                          selectedPaymentType?.code === paymentType.code
                        }
                        onSelect={(paymentTypeCode) => {
                          setSelectedPaymentTypeCode(paymentTypeCode);
                          setDrawerOpen(false);
                        }}
                      />
                    ))}
                </div>
              </div>
            </DrawerContent>
          </Drawer>
        </div>

        {canSavePaymentMethod && (
          <button
            className="align-center flex flex-row justify-between py-3"
            onClick={toggleSaveNewCreditCard}
          >
            <p className="text-md">
              {isOverwrite
                ? t(
                    'Checkout - Overwrite for future purchases',
                    'Replace previously saved {{brandName}}',
                    { brandName: selectedPaymentType.code },
                  )
                : t(
                    'Checkout - Save credit card for future purchases',
                    'Save for future purchases',
                  )}
            </p>
            <Switch id="save-credit-card" checked={saveNewCreditCard} />
          </button>
        )}

        {!selectedPaymentMethod && (
          <p className="py-2 text-sm">
            {t(
              'Checkout - Information redirection to saferpay',
              'On checkout, you’ll be redirected to a secure payment page to enter your payment details.',
            )}
          </p>
        )}
      </div>
    </div>
  );
}
