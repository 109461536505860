/**
 * Load active orders
 */

export function inAppLoadActiveOrders() {
  if (
    'loadActiveOrders' in window &&
    typeof window.loadActiveOrders === 'function'
  ) {
    window.loadActiveOrders();
  }
}

/**
 * Navigate back
 */

export const inAppCanNavigateBack =
  'navigateBack' in window && typeof window.navigateBack === 'function';

export function inAppNavigateBack() {
  if ('navigateBack' in window && typeof window.navigateBack === 'function') {
    window.navigateBack();
  }
}

/**
 * Navigate to profile
 */

export const inAppCanNavigateToProfile =
  'navigateToProfile' in window &&
  typeof window.navigateToProfile === 'function';

export function inAppNavigateToProfile() {
  if (
    'navigateToProfile' in window &&
    typeof window.navigateToProfile === 'function'
  ) {
    window.navigateToProfile();
  }
}

/**
 * Track event
 */

export const inAppCanTrackEvent =
  'trackEvent' in window && typeof window.trackEvent === 'function';

export function inAppTrackEvent(event: {
  name: string;
  data?: Record<string, unknown>;
}) {
  if ('trackEvent' in window && typeof window.trackEvent === 'function') {
    window.trackEvent(event);
  }
}
