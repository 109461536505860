import { CircleCheck } from 'lucide-react';

import { PaymentBrandIcon } from '@/app/5-checkout/1-start/components/payment-brand-icon';
import { PaymentMethodFragment } from '@/lib/__generated__/graphql';
import { cn } from '@/lib/utils';
import { isPaymentMethodExpired } from '@/stores/payment-store';

import { PaymentMethodInfo } from './payment-method-info';

type Props = {
  savedPaymentMethod: PaymentMethodFragment;
  selected: boolean;
  onSelect: (paymentMethodId: string) => void;
};

export function SavedPaymentMethodSelectItem(props: Props) {
  const isExpired = isPaymentMethodExpired(props.savedPaymentMethod);

  return (
    <div
      className={cn(
        'flex items-center justify-between',
        isExpired && 'opacity-50',
      )}
    >
      <button
        className="flex flex-1 items-center gap-5 py-3"
        onClick={() => {
          if (!isExpired) props.onSelect(props.savedPaymentMethod.id);
        }}
      >
        <PaymentBrandIcon brand={props.savedPaymentMethod.brand} />
        <PaymentMethodInfo paymentMethod={props.savedPaymentMethod} />
      </button>
      {props.selected && <CircleCheck className="mr-2 size-5" />}
    </div>
  );
}
