import { toast } from 'sonner';

import { graphql } from '@/lib/__generated__';
import { Eat_Cart_SetDeliveryAsTakeAway_Input } from '@/lib/__generated__/graphql';
import { apolloClient } from '@/lib/apollo/apollo-client';
import { getAnonId } from '@/lib/utils';

export async function setDeliveryAsTakeAwayForCart(
  input: Eat_Cart_SetDeliveryAsTakeAway_Input,
) {
  const response = await apolloClient.mutate({
    mutation: graphql(`
      mutation Eat_Cart_SetDeliveryAsTakeAway(
        $anonId: ID
        $input: Eat_Cart_SetDeliveryAsTakeAway_Input!
      ) {
        Eat_Cart_SetDeliveryAsTakeAway(anonId: $anonId, input: $input) {
          cart {
            ...Eat_Cart
          }
        }
      }
    `),
    variables: {
      anonId: getAnonId(),
      input,
    },
  });

  const cart = response.data?.Eat_Cart_SetDeliveryAsTakeAway.cart;

  if (!cart) {
    toast.error('An error occurred', { position: 'top-center' });
    throw new Error(
      response.errors?.map((err) => err.message).join(', ') ??
        'Could not update cart',
    );
  }

  return cart;
}
