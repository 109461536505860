import { Textarea } from '@/components/ui/textarea';
import { useTranslation } from '@/i18n/client';
import { useMainStore } from '@/stores/main-store';

type Props = {
  restaurantId: string;
};

export function CommentsInput(props: Props) {
  const { t } = useTranslation();

  const [commentsByRestaurantId, setCommentsForRestaurantId] = useMainStore(
    (state) => [state.commentsByRestaurantId, state.setCommentsForRestaurantId],
  );

  return (
    <Textarea
      className="text-md h-36 rounded-md p-3 placeholder:font-medium placeholder:text-gray-500 focus-visible:ring-0 focus-visible:ring-offset-0"
      placeholder={t('Details - Comments placeholder', 'Comments (optional)')}
      value={commentsByRestaurantId[props.restaurantId] ?? ''}
      onChange={(e) => {
        setCommentsForRestaurantId(e.target.value, props.restaurantId);
      }}
    />
  );
}
