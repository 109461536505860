import { useMainStore } from '@/stores/main-store';

import { FocusMenuDeal } from './focus-menu-deal';
import { FocusMenuItem } from './focus-menu-item';

type Props = {
  restaurantId: string;
  sku: string;
};

export function FocusMenuEntry(props: Props) {
  const { menu, cart } = useMainStore((state) => ({
    menu: state.menu,
    cart: state.cart,
  }));

  const menuEntry = menu?.sections
    .filter((section) => section.enabled)
    .flatMap((section) => section.entries)
    .find(
      (item) =>
        item.sku === props.sku &&
        (item.enabled ||
          cart?.entries.some((cartEntry) => cartEntry.sku === item.sku)),
    );

  if (!menuEntry) {
    return null;
  }

  switch (menuEntry.__typename) {
    case 'Eat_Menu_Item':
      return (
        <FocusMenuItem menuItem={menuEntry} restaurantId={props.restaurantId} />
      );

    case 'Eat_Menu_Deal':
      return (
        <FocusMenuDeal menuDeal={menuEntry} restaurantId={props.restaurantId} />
      );

    default:
      return null;
  }
}
