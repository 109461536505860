import { SendIcon } from 'lucide-react';

import { Button } from '@/components/ui/button';
import { useTranslation } from '@/i18n/client';
import {
  requestPushPermission,
  usePushPermissionStore,
} from '@/stores/push-permission-store';

export function PushPermissionBlock() {
  const { t } = useTranslation();

  const { pushPermissionGranted, canAskForPushPermissionAgain } =
    usePushPermissionStore();

  return (
    <div className="flex w-full items-center gap-3 rounded-md border px-2 py-2 has-[:focus]:border-border">
      <SendIcon className="ml-1 mt-0.5 size-6" />
      <div className="ml-2 flex flex-1 flex-col gap-1 border-none px-0 focus-visible:ring-0 focus-visible:ring-offset-0">
        <h3 className="text-md font-medium">
          {t('Cart summary - Push notifications', 'Push notifications')}
          <span>*</span>
        </h3>
        {pushPermissionGranted && (
          <p className="mb-2 text-sm">
            {t(
              'Cart summary - Push notifications - Enabled description',
              'You have push notifications enabled. We’ll notify you as soon as your order is ready.',
            )}
          </p>
        )}

        {!pushPermissionGranted && !canAskForPushPermissionAgain && (
          <>
            <p className="mb-2 text-sm">
              {t(
                'Cart summary - Push notifications - Blocked description',
                'You must have push notifications enabled to place an order. Please go to your phone’s settings to enable them for LAAX App.',
              )}
            </p>
            <Button
              variant="primary"
              type="button"
              onClick={requestPushPermission}
            >
              {t(
                'Cart summary - Push notifications - Go to settings button text',
                'Go to settings',
              )}
            </Button>
          </>
        )}

        {!pushPermissionGranted && canAskForPushPermissionAgain && (
          <>
            <p className="mb-2 text-sm">
              {t(
                'Cart summary - Push notifications - Description',
                'Turn on push notifications, and we’ll notify you as soon as your order is ready.',
              )}
            </p>
            <Button
              variant="primary"
              type="button"
              onClick={requestPushPermission}
            >
              {t(
                'Cart summary - Push notifications - Enable push button text',
                'Enable push notifications',
              )}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
