import { formatRelative } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import i18next from 'i18next';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Eat_RestaurantFragment } from '@/lib/__generated__/graphql';
import { cn } from '@/lib/utils';

import { WaitingTimeBanner } from './menu-warning-banner';

export function RestaurantStatus({
  status,
  closesAtTime,
  opensAt,
  waitingTimeMinutes,
}: Pick<
  Eat_RestaurantFragment,
  'status' | 'closesAtTime' | 'opensAt' | 'waitingTimeMinutes'
>) {
  const { t } = useTranslation();

  switch (status) {
    case 'ACCEPTING_ORDERS':
      return (
        <>
          <div className="flex items-center gap-2 px-[0.6rem] text-sm">
            <img
              className="h-5 w-5 object-contain"
              src={`/icons/success.svg`}
            />
            <span>
              {t(
                'Menu - Header - Status - Accepting',
                'Accepting online orders until',
              )}{' '}
              <b>{closesAtTime}</b>
            </span>
          </div>
          <WaitingTimeBanner waitingTimeMinutes={waitingTimeMinutes} />
        </>
      );
    case 'NOT_ACCEPTING_ORDERS':
      return (
        <NotAvailableBanner>
          {opensAt ? (
            <>
              {t(
                'Menu - Header - Status - Not Accepting',
                'Accepting online orders from',
              )}{' '}
              <b>{formatOpensAt(opensAt)}</b>
            </>
          ) : (
            t(
              'Menu - Header - Status - Disabled',
              'Not accepting online orders for the moment',
            )
          )}{' '}
        </NotAvailableBanner>
      );
    case 'MOBILE_ORDERING_TURNED_OFF':
      return (
        <NotAvailableBanner>
          {t(
            'Menu - Header - Status - Disabled',
            'Not accepting online orders for the moment',
          )}
        </NotAvailableBanner>
      );
    default:
      return null;
  }
}

export function RestaurantStatusCompact({
  status,
  closesAtTime,
  opensAt,
  waitingTimeMinutes,
}: Pick<
  Eat_RestaurantFragment,
  'status' | 'closesAtTime' | 'opensAt' | 'waitingTimeMinutes'
>) {
  const { t } = useTranslation();

  const text = (() => {
    switch (status) {
      case 'ACCEPTING_ORDERS':
        return (
          t(
            'Menu - Header - Status - Accepting',
            'Accepting online orders until',
          ) + ` ${closesAtTime}`
        );
      case 'NOT_ACCEPTING_ORDERS':
        return opensAt
          ? t(
              'Menu - Header - Status - Not Accepting',
              'Accepting online orders from',
            ) + ` ${formatOpensAt(opensAt)}`
          : t(
              'Menu - Header - Status - Disabled',
              'Not accepting online orders for the moment',
            );
      case 'MOBILE_ORDERING_TURNED_OFF':
        return t(
          'Menu - Header - Status - Disabled',
          'Not accepting online orders for the moment',
        );
      default:
        return '';
    }
  })();

  return (
    <>
      {status === 'ACCEPTING_ORDERS' ? (
        <WaitingTimeBanner waitingTimeMinutes={waitingTimeMinutes} compact />
      ) : (
        <div className="text-md flex items-end justify-center gap-2">
          <img
            className="-mb-[0.05rem] h-5 w-5 object-contain"
            src={`/icons/fail.svg`}
          />
          <span className="text-sm">{text}</span>
        </div>
      )}
    </>
  );
}

function NotAvailableBanner({ children }: { children: ReactNode }) {
  return (
    <>
      <div
        className={cn('relative mb-4 flex gap-2 rounded-2xl border px-2 py-4')}
      >
        <img
          className="mt-[0.1rem] h-5 w-5 object-contain"
          src={`/icons/fail.svg`}
        />

        <div className="flex-1">
          <p className="text-sm leading-[1.2rem]">{children}</p>
        </div>

        <div className="absolute -bottom-[6.5px] left-[40px] h-[1px] w-4 rotate-45 transform rounded-md bg-border" />
        <div className="absolute -bottom-[6.5px] left-[50.8px] h-[1px] w-4 -rotate-45 transform rounded-md bg-border" />
        <div className="absolute -bottom-[1.5px] left-[43.25px] h-[3px] w-[20.4px] transform rounded-md bg-white" />
      </div>
    </>
  );
}

function formatOpensAt(opensAt: Date | null) {
  if (!opensAt) return '?';

  const selectedLocale = i18next.language === 'de' ? de : enUS;
  return formatRelative(opensAt, new Date(), { locale: selectedLocale });
}
