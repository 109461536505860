import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getCart } from '@/actions/cart/get-cart';
import { DrawerLayout } from '@/components/common/drawer/drawer-layout';
import { useParamsRequired } from '@/lib/navigation';
import { useMainStore } from '@/stores/main-store';

import { CartList } from './components/cart-list';
import { FocusCartEntry } from './components/focus-cart-entry';

export function CartPage() {
  const { restaurantId } = useParamsRequired('restaurantId');
  const { cartEntryId } = useParams();
  const setCart = useMainStore((store) => store.setCart);

  useEffect(() => {
    getCart({ restaurantId }).then(setCart);
  }, [restaurantId]);

  return (
    <DrawerLayout
      drawer={
        cartEntryId && (
          <FocusCartEntry
            restaurantId={restaurantId}
            cartEntryId={cartEntryId}
          />
        )
      }
    >
      <CartList restaurantId={restaurantId} />
    </DrawerLayout>
  );
}
