import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useState } from 'react';

import { HasDisabledEntriesBanner } from '@/app/shared-components/has-disabled-entries-banner';
import { RestaurantStatus } from '@/app/shared-components/restaurant-status';
import { FadeFromBelow } from '@/components/common/fade-from-below';
import { OrderFooter } from '@/components/common/order-footer';
import { Button } from '@/components/ui/button';
import { useTranslation } from '@/i18n/client';
import { useImagePreload } from '@/lib/hooks';
import { Link, useNavigate } from '@/lib/navigation';
import { useMainStore } from '@/stores/main-store';

import { CartListItem } from './cart-list-item';
import { TeaserDrawer } from './teaser-drawer';

export function CartList(props: { restaurantId: string }) {
  useImagePreload('/icons/empty-cart.svg');

  const { t } = useTranslation();

  const { menu, restaurant, cart } = useMainStore((state) => ({
    cart: state.cart,
    menu: state.menu,
    restaurant: state.restaurant,
  }));

  const [animationContainerRef] = useAutoAnimate({ duration: 200 });

  const navigate = useNavigate();

  const [teaserDrawerOpen, setTeaserDrawerOpen] = useState(false);

  if (menu === null || cart === null || restaurant === null) {
    return null;
  }

  function navigateToDetails() {
    navigate(`/${props.restaurantId}/details`);
  }

  function handleContinue() {
    if (cart?.teasers && cart.teasers.length > 0) {
      setTeaserDrawerOpen(true);
    } else {
      navigateToDetails();
    }
  }

  const hasDisabledEntries = cart.entries.some((entry) => {
    return entry.__typename === 'Eat_Cart_Item'
      ? !entry.menuItem.enabled
      : !entry.menuDeal.enabled;
  });

  return (
    <>
      <div className="mt-16 flex flex-col gap-5 px-5">
        {hasDisabledEntries ? (
          <HasDisabledEntriesBanner />
        ) : (
          <RestaurantStatus {...restaurant} />
        )}
        <div ref={animationContainerRef}>
          {cart.entries.map((cartEntry) => {
            return (
              <CartListItem
                key={cartEntry.id}
                restaurantId={props.restaurantId}
                cartEntry={cartEntry}
                menu={menu}
              />
            );
          })}
        </div>

        {cart.entries.length === 0 && (
          <EmptyCart restaurantId={props.restaurantId} />
        )}
      </div>
      <TeaserDrawer
        open={teaserDrawerOpen}
        onOpenChange={setTeaserDrawerOpen}
        onContinue={navigateToDetails}
      />
      <OrderFooter
        buttonDisabled={
          restaurant.status !== 'ACCEPTING_ORDERS' ||
          cart.entries.length === 0 ||
          hasDisabledEntries
        }
        buttonText={t('Cart summary - Button - At cart page', 'Continue')}
        onButtonClick={handleContinue}
        stepNumber={2}
      />
    </>
  );
}

function EmptyCart(props: { restaurantId: string }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-4">
      <img
        src="/icons/empty-cart.svg"
        className="my-6 object-contain"
        style={{ imageRendering: 'crisp-edges' }}
        alt=""
      />
      <FadeFromBelow delay={50}>
        <h3 className="text-2xl font-bold">
          {t('Cart empty - Title', 'Your cart is empty')}
        </h3>
      </FadeFromBelow>
      <FadeFromBelow delay={100}>
        <p className="px-6 text-center text-muted-foreground">
          {t(
            'Cart empty - Description',
            'Have a look at our menu to see what you fancy and add it to the cart',
          )}
        </p>
      </FadeFromBelow>
      <FadeFromBelow delay={150} className="mt-6 w-full">
        <Button asChild className="w-full items-center">
          <Link to={`/${props.restaurantId}/menu`} replace>
            {t('Cart empty - Button text', 'Back to menu')}
          </Link>
        </Button>
      </FadeFromBelow>
    </div>
  );
}
