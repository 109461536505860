import { ArrowLeft, ShoppingBagIcon, X } from 'lucide-react';
import { useCallback, useState } from 'react';

import { setDeliveryAsTakeAwayForCart } from '@/actions/cart/set-delivery-as-take-away-for-cart';
import { setDeliveryToLocationForCart } from '@/actions/cart/set-delivery-to-location-for-cart';
import { DeliveryLocationRow } from '@/app/4-order-details/components/delivery-location-row';
import { ContentSlider } from '@/components/common/content-slider';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerTitle,
  DrawerTrigger,
} from '@/components/ui/drawer';
import { useTranslation } from '@/i18n/client';
import { cn } from '@/lib/utils';
import { useDerivedDeliveryValues, useMainStore } from '@/stores/main-store';

export function DeliveryLocationsBlock() {
  const { t } = useTranslation();

  const [activeDeliveryLocationId, setActiveDeliveryLocationId] = useState<
    string | null
  >(null);
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => setActiveDeliveryLocationId(null), 500);
  }, []);

  const {
    cart,
    restaurant,
    deliveryTakeAwaySelected,
    setDeliveryToLocation,
    setDeliveryAsTakeAway,
    setCart,
  } = useMainStore((state) => ({
    cart: state.cart,
    restaurant: state.restaurant,
    deliveryTakeAwaySelected: state.deliveryTakeAwaySelected,
    setDeliveryToLocation: state.setDeliveryToLocation,
    setDeliveryAsTakeAway: state.setDeliveryAsTakeAway,
    setCart: state.setCart,
  }));

  const {
    isDeliveryOptionSelected,
    selectedDeliveryLocation,
    selectedDeliverySubLocation,
  } = useDerivedDeliveryValues();

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <button
          className="flex w-full items-center gap-3 rounded-md border px-2 py-2 text-left focus:outline-none"
          type="button"
          onClick={() => setOpen(true)}
        >
          <ShoppingBagIcon className="ml-1 mt-0.5 size-6" />
          <div className="ml-2 flex flex-1 flex-col gap-1">
            <h3
              className={cn(
                'text-md font-medium',
                !isDeliveryOptionSelected && 'text-gray-500',
              )}
            >
              {t('Cart summary - Delivery location', 'Delivery location')}
              <span>*</span>
            </h3>
            {selectedDeliveryLocation ? (
              <div className="mb-2 flex flex-col gap-1">
                <p className="text-sm">{selectedDeliveryLocation.name}</p>
                {selectedDeliverySubLocation && (
                  <p className="text-sm text-gray-600">
                    {selectedDeliverySubLocation.name}
                  </p>
                )}
              </div>
            ) : deliveryTakeAwaySelected ? (
              <p className="mb-2 text-sm">
                {t('Cart summary - Delivery location - Take-away', 'Take-away')}
              </p>
            ) : null}
          </div>
          <p className="ml-2 text-sm text-action-blue">
            {isDeliveryOptionSelected
              ? t('Cart summary - Delivery location - Change', 'change')
              : t('Cart summary - Delivery location - Select', 'select')}
          </p>
        </button>
      </DrawerTrigger>

      <DrawerOverlay className="bg-gray-300 bg-opacity-10" />

      <DrawerContent className="flex h-full flex-col">
        <DrawerHeader className="relative flex justify-center border-b border-gray-200 py-4">
          {activeDeliveryLocationId && (
            <button
              className="absolute bottom-0 left-0 top-0 flex items-center justify-center p-6"
              onClick={() => setActiveDeliveryLocationId(null)}
            >
              <ArrowLeft className="box-content size-5" />
            </button>
          )}
          <DrawerTitle className="my-2">
            {t(
              'Cart summary - Select delivery location',
              'Select delivery location',
            )}
          </DrawerTitle>
          <DrawerClose asChild>
            <div className="absolute bottom-0 right-0 top-0 flex items-center justify-center p-6">
              <X className="box-content size-5" onClick={onClose} />
            </div>
          </DrawerClose>
        </DrawerHeader>

        <ContentSlider
          contentIndex={activeDeliveryLocationId ? 1 : 0}
          axis="horizontal"
          duration={500}
          className="h-full overflow-y-auto"
        >
          <div className="mx-2 mt-4 flex-1 overflow-y-auto px-2 pb-4">
            {!activeDeliveryLocationId ? (
              <ul>
                <DeliveryLocationRow
                  onClick={async () => {
                    const newCart = await setDeliveryAsTakeAwayForCart({
                      cartId: cart?.id ?? '',
                      restaurantId: restaurant?.id ?? '',
                    });
                    setCart(newCart);
                    setDeliveryAsTakeAway();
                    onClose();
                  }}
                  label={t(
                    'Cart summary - Delivery location - Take-away',
                    'Take-away',
                  )}
                  chargeText="0.00 CHF"
                />
                {restaurant?.deliveryLocations?.map((location) => (
                  <DeliveryLocationRow
                    key={location.id}
                    onClick={async () => {
                      setActiveDeliveryLocationId(location.id);
                    }}
                    label={location.name}
                    chargeText={`${(location.deliveryChargeRappen / 100).toFixed(2)} CHF`}
                    hasSubLocations={
                      location.subLocations != null &&
                      location.subLocations.length > 0
                    }
                  />
                ))}
              </ul>
            ) : (
              <ul>
                {restaurant?.deliveryLocations
                  ?.find((location) => location.id === activeDeliveryLocationId)
                  ?.subLocations?.map((subLocation) => (
                    <DeliveryLocationRow
                      key={subLocation.id}
                      label={subLocation.name}
                      onClick={async () => {
                        const newCart = await setDeliveryToLocationForCart({
                          cartId: cart?.id ?? '',
                          restaurantId: restaurant.id,
                          deliveryLocationId: activeDeliveryLocationId,
                          deliverySubLocationId: subLocation.id,
                        });
                        setCart(newCart);
                        setDeliveryToLocation(
                          activeDeliveryLocationId,
                          subLocation.id,
                        );
                        onClose();
                      }}
                    />
                  ))}
              </ul>
            )}
          </div>
        </ContentSlider>
      </DrawerContent>
    </Drawer>
  );
}
