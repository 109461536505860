import { useTranslation } from '@/i18n/client';
import { Eat_CartFragment } from '@/lib/__generated__/graphql';
import { rappenToFrancs } from '@/lib/utils';

export function CartEntry(props: {
  cartEntry: Eat_CartFragment['entries'][number];
}) {
  const { t } = useTranslation();

  const isEnabled =
    props.cartEntry.__typename === 'Eat_Cart_Item'
      ? props.cartEntry.menuItem.enabled
      : props.cartEntry.menuDeal.enabled;

  return (
    <div className="flex items-center justify-between">
      <p className="text-md">
        <span className="text-red-700">{props.cartEntry.quantity + '×'}</span>{' '}
        {props.cartEntry.__typename === 'Eat_Cart_Deal'
          ? props.cartEntry.menuDeal.name
          : props.cartEntry.menuItem.name}
      </p>

      {!isEnabled && (
        <p className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
          {t('List Entry - Not enabled', 'Not available')}
        </p>
      )}

      <p className="text-md font-medium">
        {rappenToFrancs(props.cartEntry.priceRappen)}{' '}
        <span className="text-xs font-medium">CHF</span>
      </p>
    </div>
  );
}
