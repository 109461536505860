import { useTranslation } from 'react-i18next';

import { cn } from '@/lib/utils';

export function HasDisabledEntriesBanner() {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'relative mb-4 flex gap-2 rounded-2xl border bg-red-50 px-2 py-4',
      )}
    >
      <img
        className="mt-[0.1rem] h-5 w-5 object-contain"
        src={`/icons/fail.svg`}
      />

      <div className="flex-1">
        <p className="text-sm leading-[1.2rem] text-red-700">
          {t(
            'Cart - Disabled Items Warning',
            'Some items in the cart are out of stock at the moment. Remove them to continue to check out',
          )}
        </p>
      </div>
    </div>
  );
}
