import { Button } from '@/components/ui/button';
import { useTranslation } from '@/i18n/client';
import { useNavigate } from '@/lib/navigation';
import { useMainStore } from '@/stores/main-store';

export function BackToRestaurantsButton() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const canNavigateBackToStartPage = useMainStore(
    (state) => state.canNavigateBackToStartPage,
  );

  if (!canNavigateBackToStartPage) {
    return null;
  }

  return (
    <Button
      className="hover:bg-secondarydark w-full bg-secondary text-black"
      onClick={() => navigate('/')}
    >
      {t('Restaurant - Back to restaurants', 'Back to restaurants')}
    </Button>
  );
}
