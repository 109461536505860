import { useTranslation } from 'react-i18next';

import { RestaurantStatus } from '@/app/shared-components/restaurant-status';
import { Eat_RestaurantFragment } from '@/lib/__generated__/graphql';

type Props = {
  restaurant: Eat_RestaurantFragment;
};

export function MenuHeader(props: Props) {
  return (
    <div className="sticky top-16 mt-16 flex w-full flex-col gap-5 px-3">
      <RestaurantStatus {...props.restaurant} />
      {props.restaurant.id === 'il-paun' && <IlPaunInfo />}
    </div>
  );
}

/**
 * This is a temporary fix to show an info message for the restaurant il paun.
 * Should be replaced with a proper solution like a description field in the restaurant model.
 */
function IlPaunInfo() {
  const { t } = useTranslation();

  return (
    <div className="text-sm">
      {t(
        'Menu - Header - Il Paun Info',
        'Order your fresh bread for the next day easily by 2:00 PM. You can pick up your order the following morning from 7:30 AM at il Paun.',
      )}
    </div>
  );
}
