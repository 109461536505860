import React, { ReactNode } from 'react';
import Markdown from 'react-markdown';

import { AllergenIcons } from '@/components/common/allergens';
import { useTranslation } from '@/i18n/client';
import { Eat_Menu_Allergen } from '@/lib/__generated__/graphql';
import { Link } from '@/lib/navigation';
import { cn } from '@/lib/utils';

type Props = {
  name: ReactNode;
  enabled?: boolean;
  description?: string | null;
  priceText?: ReactNode;
  allergens?: Eat_Menu_Allergen[] | null;
  imageUrl?: string | null;
  highlighted?: boolean;
  onImageClick?(event: React.MouseEvent): void;
  imageClassName?: string;
  imageLinkHref?: string;
  imageIcon?: ReactNode;
  children?: ReactNode;
  className?: string;
};

export function ListEntry({
  name,
  enabled,
  description,
  imageUrl,
  priceText,
  allergens,
  highlighted,
  onImageClick,
  imageLinkHref,
  imageIcon,
  imageClassName,
  children,
  className,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={cn('my-5 flex w-full gap-4 ', className)}>
      <ImageWrapper
        imageLinkHref={imageLinkHref}
        onClick={onImageClick}
        className={cn(
          'relative h-[5.75rem] w-[5.75rem] rounded-md',
          imageUrl == null && 'bg-muted',
        )}
      >
        {imageUrl && (
          <img
            loading="lazy"
            src={imageUrl ?? ''}
            alt={typeof name === 'string' ? name : ''}
            className={cn(
              'block h-full w-full rounded-sm transition-all duration-200',
              highlighted && 'shadow-[0_0_0_3px] shadow-laaxred',
              onImageClick && 'cursor-pointer',
              highlighted && 'animate-press-in',
              !enabled && 'opacity-50 grayscale',
              imageClassName,
            )}
          />
        )}
        {imageIcon && (
          <div className="absolute bottom-0 right-0 flex h-7 w-7 translate-x-1.5 translate-y-1/4 items-center justify-center rounded-full border border-border bg-background text-xs">
            {imageIcon}
          </div>
        )}
      </ImageWrapper>
      <div className="flex flex-1 flex-col items-stretch ">
        <div className="flex flex-1 flex-row gap-3">
          <div className="flex-1">
            {!enabled && (
              <span className="mb-2 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                {t('List Entry - Not enabled', 'Out of stock')}
              </span>
            )}
            <h4
              className={cn(
                'mb-1 leading-5',
                !enabled && 'text-muted-foreground',
              )}
            >
              {name}
            </h4>

            {description && (
              <div className="max-h-16 overflow-hidden text-ellipsis text-sm leading-5 text-muted-foreground">
                <Markdown>{description}</Markdown>
              </div>
            )}
          </div>
          <div className="flex h-full flex-col items-end justify-end">
            <div className="flex-1">{children}</div>
          </div>
        </div>
        <div className="mt-1 flex flex-row items-center justify-between">
          <div>
            <AllergenIcons allergens={allergens} />
          </div>
          <div
            className={cn(
              'text-sm leading-3',
              !enabled && 'text-muted-foreground',
            )}
          >
            {priceText}
          </div>
        </div>
      </div>
    </div>
  );
}

const ImageWrapper = ({
  imageLinkHref,
  ...props
}: {
  imageLinkHref?: string;
  children: ReactNode;
  className: string;
  onClick?(event: React.MouseEvent): void;
}) =>
  imageLinkHref ? (
    <Link to={imageLinkHref} preventScrollReset {...props} />
  ) : (
    <div {...props} />
  );
