import { useEffect, useState } from 'react';
import { toast } from 'sonner';

import { getCart } from '@/actions/cart/get-cart';
import { payWithSavedPaymentMethod } from '@/actions/payment/pay-with-saved-payment-method';
import { CheckoutOrderDetailsSection } from '@/app/5-checkout/1-start/sections/checkout-order-details-section';
import { CheckoutOrderItemsSection } from '@/app/5-checkout/1-start/sections/checkout-order-items-section';
import { CheckoutPaymentSection } from '@/app/5-checkout/1-start/sections/checkout-payment-section';
import { CheckoutTermsAndConditionsSection } from '@/app/5-checkout/1-start/sections/checkout-terms-and-conditions-section';
import { CheckoutTippingSection } from '@/app/5-checkout/1-start/sections/checkout-tipping-section';
import { HasDisabledEntriesBanner } from '@/app/shared-components/has-disabled-entries-banner';
import { RestaurantStatusCompact } from '@/app/shared-components/restaurant-status';
import { OrderFooter } from '@/components/common/order-footer';
import { useTranslation } from '@/i18n/client';
import { useIsAuthenticated } from '@/lib/auth';
import { createOrderForDiningMode, initializeWebPayment } from '@/lib/checkout';
import { useNavigate, useParamsRequired } from '@/lib/navigation';
import { isErrorWithMessage } from '@/lib/utils';
import { useDerivedDeliveryValues, useMainStore } from '@/stores/main-store';
import { usePayment } from '@/stores/payment-store';

import { FullScreenLoader } from '../../shared-components/full-screen-loader';

export function CheckoutStartPage() {
  const navigate = useNavigate();
  const { restaurantId } = useParamsRequired('restaurantId');

  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated();

  const {
    cart,
    restaurant,
    nameOnReceipt,
    commentOnReceipt,
    tableNumber,
    phoneNumber,
    setCart,
  } = useMainStore((state) => ({
    cart: state.cart,
    menu: state.menu,
    restaurant: state.restaurant,
    nameOnReceipt: state.nameOnReceipt,
    commentOnReceipt: state.commentsByRestaurantId[restaurantId],
    tableNumber: state.tableNumber,
    phoneNumber: state.phoneNumber,
    setCart: state.setCart,
  }));

  useEffect(() => {
    getCart({ restaurantId }).then(setCart);
  }, [restaurantId]);

  const {
    selectedDeliveryLocation,
    selectedDeliverySubLocation,
    isDeliveryOptionSelected,
  } = useDerivedDeliveryValues();

  const deliveryDetails = isDeliveryOptionSelected
    ? selectedDeliveryLocation
      ? selectedDeliverySubLocation
        ? `${selectedDeliveryLocation.name}, ${selectedDeliverySubLocation.name}`
        : selectedDeliveryLocation.name
      : t('Cart summary - Delivery location - Take-away', 'Take-away')
    : null;

  const {
    selectedPaymentType,
    selectedPaymentMethod,
    saveNewCreditCard,
    hasValidPaymentSelected,
  } = usePayment();

  const [isTipping, setIsTipping] = useState(false);

  const [isLoadingStartWebCheckout, setIsLoadingStartWebCheckout] =
    useState(false);

  const hasDisabledEntries = cart?.entries.some((entry) => {
    return entry.__typename === 'Eat_Cart_Item'
      ? !entry.menuItem.enabled
      : !entry.menuDeal.enabled;
  });

  async function startCheckout(tipRappen: number) {
    // FIXME: These should open the respective dialogs to set the value, not just show error toasts
    if (!cart || !restaurant) {
      toast.error(t('Toast - Cart not found', 'Cart not found'));
      return;
    }

    setIsLoadingStartWebCheckout(true);

    const order = await createOrderForDiningMode(
      {
        diningMode: restaurant.diningMode,
        input: {
          contactNumberAsE164: phoneNumber,
          entriesTotalRappen: cart.entriesTotalRappen,
          nameOnReceipt,
          commentOnReceipt,
          restaurantId,
          tipRappen,
          tableNumber,
          deliveryChargeRappen:
            selectedDeliveryLocation?.deliveryChargeRappen ?? 0,
          deliveryLocationId: selectedDeliveryLocation?.id ?? null,
          deliverySubLocationId: selectedDeliverySubLocation?.id ?? null,
        },
      },
      t,
    ).catch((err) => {
      toast.error(err.message, {
        duration: Infinity,
        closeButton: true,
      });
    });

    if (!order) {
      setIsLoadingStartWebCheckout(false);
      return;
    }

    try {
      //New payment method
      if (selectedPaymentType) {
        await initializeWebPayment({
          isAuthenticated,
          orderId: order.id,
          restaurantId,
          saveNewCreditCard,
          paymentTypeCode: selectedPaymentType.code,
        });
      }
      //Saved payment method selected
      else if (selectedPaymentMethod) {
        const { order: paidOrder, error } = await payWithSavedPaymentMethod({
          input: {
            orderId: order.id,
            aliasId: selectedPaymentMethod.token, // FIXME: Rename this field on the API
          },
        });

        if (!paidOrder) {
          setIsLoadingStartWebCheckout(false);
          toast.error(
            error?.message ??
              t(
                'Toast - Something went wrong with your payment',
                'Something went wrong with your payment',
              ),
            { duration: 5000 },
          );
          return;
        }
        navigate(`/${restaurantId}/checkout/success?orderId=${order.id}`);
      } else {
        // TODO | This should not just result in an error
        // TODO | We should implement the same behaviour as the app, where pressing
        // TODO | the checkout button with information missing opens the corresponding
        // TODO | dialog to enter that info (such as no payment method selected)
        throw new Error(
          t('Toast - No payment method selected', 'No payment method selected'),
        );
      }
    } catch (err: unknown) {
      setIsLoadingStartWebCheckout(false);
      toast.error(
        isErrorWithMessage(err)
          ? err.message
          : `Non error thrown of type ${typeof err}`,
      );
    }
  }

  if (cart === null || restaurant === null) {
    return null;
  }

  return (
    <>
      <main className="mt-14 flex flex-col divide-y-2 divide-muted">
        <CheckoutOrderDetailsSection
          restaurant={restaurant}
          tableNumber={tableNumber}
          nameOnReceipt={nameOnReceipt}
          phoneNumnber={phoneNumber}
          deliveryDetails={deliveryDetails}
        />
        <div className="mx-5 pt-5">
          {hasDisabledEntries ? (
            <HasDisabledEntriesBanner />
          ) : (
            <RestaurantStatusCompact {...restaurant} />
          )}
        </div>
        <CheckoutOrderItemsSection restaurantId={restaurant.id} cart={cart} />
        <CheckoutPaymentSection />
        <CheckoutTermsAndConditionsSection />
      </main>

      <CheckoutTippingSection
        open={isTipping}
        cartTotalRappen={cart.cartTotalRappen}
        onCancel={() => setIsTipping(false)}
        onSelection={(tipRappen) => {
          setIsTipping(false);
          startCheckout(tipRappen);
        }}
      />

      {isLoadingStartWebCheckout && <FullScreenLoader />}

      <OrderFooter
        stepNumber={4}
        buttonText={t('Checkout - Footer button - To payment', 'To payment')}
        onButtonClick={() => setIsTipping(true)}
        buttonDisabled={
          restaurant.status !== 'ACCEPTING_ORDERS' ||
          isLoadingStartWebCheckout ||
          !hasValidPaymentSelected ||
          !cart ||
          cart.entries.length === 0 ||
          hasDisabledEntries
        }
      />
    </>
  );
}
