import { User } from 'lucide-react';
import { useEffect } from 'react';

import { IconInput } from '@/app/4-order-details/components/icon-input';
import { useTranslation } from '@/i18n/client';
import { useMainStore } from '@/stores/main-store';
import { useUserProfile } from '@/stores/user-profile-store';

export function NameInput() {
  const { t } = useTranslation();
  const { userProfile } = useUserProfile();

  const [nameOnReceipt, setNameOnReceipt] = useMainStore((state) => [
    state.nameOnReceipt,
    state.setNameOnReceipt,
  ]);

  useEffect(() => {
    if (!nameOnReceipt && userProfile?.firstName) {
      setNameOnReceipt(userProfile.firstName);
    }
  }, [userProfile?.firstName]);

  return (
    <IconInput
      icon={<User className="size-6 shrink-0" />}
      required
      className="border-none px-0 focus-visible:ring-0 focus-visible:ring-offset-0"
      value={nameOnReceipt}
      onChange={(e) => setNameOnReceipt(e.target.value)}
      placeholder={t('Details - Your name placeholder', 'Your name')}
    />
  );
}
