import { RestaurantRedirect } from './components/restaurant-redirect';

export function InternalStartPage() {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-2">
      <p className="text-2xl font-semibold">Welcome to LAAX Eat</p>

      <RestaurantRedirect
        restaurants={[
          {
            restaurantId: 'cafe-piazza',
            restaurantName: 'Piazza',
            usesTableNumber: true,
          },
          {
            restaurantId: 'camino-ma',
            restaurantName: 'Camino MA',
            usesTableNumber: true,
          },
          {
            restaurantId: 'cafe-piazza-tk',
            restaurantName: 'Piazza Take-Away',
            usesTableNumber: false,
          },
          {
            restaurantId: 'nooba-delivery',
            restaurantName: 'Nooba Delivery',
            usesTableNumber: false,
          },
        ]}
      />
    </div>
  );
}
