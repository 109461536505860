import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import {
  Eat_CartFragment,
  Eat_Menu,
  Eat_RestaurantFragment,
} from '@/lib/__generated__/graphql';

interface MainStoreType {
  canNavigateBackToStartPage: boolean;
  setCanNavigateBackToStartPage: (canNavigateBackToStartPage: boolean) => void;

  nameOnReceipt: string;
  setNameOnReceipt: (name: string) => void;

  tableNumber: string | null;
  setTableNumber: (tableNumber: string) => void;

  commentsByRestaurantId: Record<string, string>;
  setCommentsForRestaurantId: (comments: string, restaurantId: string) => void;

  restaurant: Eat_RestaurantFragment | null;
  setRestaurant: (restaurant: Eat_RestaurantFragment) => void;

  menu: Eat_Menu | null;
  setMenu: (menu: Eat_Menu) => void;

  cart: Eat_CartFragment | null;
  setCart: (cart: Eat_CartFragment) => void;

  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;

  setDeliveryToLocation: (
    deliveryLocationId: string,
    deliverySubLocationId: string | null,
  ) => void;
  setDeliveryAsTakeAway: () => void;
  clearDeliverySelection: () => void;

  deliveryLocationId: string | null;
  deliverySubLocationId: string | null;
  deliveryTakeAwaySelected: boolean;
}

export const useMainStore = create<MainStoreType>()(
  persist(
    (set, get) => ({
      canNavigateBackToStartPage: false,
      setCanNavigateBackToStartPage: (canNavigateBackToStartPage) =>
        set({ canNavigateBackToStartPage }),

      nameOnReceipt: '',
      setNameOnReceipt: (nameOnReceipt) => set({ nameOnReceipt }),

      tableNumber: null as string | null,
      setTableNumber: (tableNumber) => set({ tableNumber }),

      commentsByRestaurantId: {},
      setCommentsForRestaurantId: (comments, restaurantId) =>
        set((state) => ({
          commentsByRestaurantId: {
            ...state.commentsByRestaurantId,
            [restaurantId]: comments,
          },
        })),

      restaurant: null,
      setRestaurant: (restaurant) => set({ restaurant }),

      menu: null,
      setMenu: (menu) => set({ menu }),

      cart: null,
      setCart: (cart) => set({ cart }),

      phoneNumber: '',
      setPhoneNumber: (phoneNumber) => set({ phoneNumber }),

      deliveryLocationId: null,
      deliverySubLocationId: null,
      deliveryTakeAwaySelected: false,

      setDeliveryToLocation: (deliveryLocationId, deliverySubLocationId) => {
        set({
          deliveryLocationId,
          deliverySubLocationId,
          deliveryTakeAwaySelected: false,
        });
      },

      setDeliveryAsTakeAway: () => {
        set({
          deliveryLocationId: null,
          deliverySubLocationId: null,
          deliveryTakeAwaySelected: true,
        });
      },

      clearDeliverySelection: () => {
        set({
          deliveryLocationId: null,
          deliverySubLocationId: null,
          deliveryTakeAwaySelected: false,
        });
      },
    }),
    {
      // FIXME: For how long should these be persisted?
      name: 'main-store',
      partialize: (state) => ({
        nameOnReceipt: state.nameOnReceipt,
        tableNumber: state.tableNumber,
        commentsByRestaurantId: state.commentsByRestaurantId,
        phoneNumber: state.phoneNumber,
        deliveryLocationId: state.deliveryLocationId,
        deliverySubLocationId: state.deliverySubLocationId,
        deliveryTakeAwaySelected: state.deliveryTakeAwaySelected,
      }),
    },
  ),
);

export function useDerivedDeliveryValues() {
  const {
    restaurant,
    deliveryLocationId,
    deliverySubLocationId,
    deliveryTakeAwaySelected,
  } = useMainStore((state) => ({
    restaurant: state.restaurant,
    deliveryLocationId: state.deliveryLocationId,
    deliverySubLocationId: state.deliverySubLocationId,
    deliveryTakeAwaySelected: state.deliveryTakeAwaySelected,
  }));

  const isDeliveryOptionSelected =
    deliveryLocationId !== null || deliveryTakeAwaySelected;

  const selectedDeliveryLocation =
    restaurant?.deliveryLocations?.find(
      (location) => location.id === deliveryLocationId,
    ) || null;

  const selectedDeliverySubLocation =
    selectedDeliveryLocation?.subLocations?.find(
      (subLocation) => subLocation.id === deliverySubLocationId,
    ) || null;

  return {
    isDeliveryOptionSelected,
    selectedDeliveryLocation,
    selectedDeliverySubLocation,
  };
}
