import { ComponentProps, ReactNode } from 'react';

import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';

export function IconInput({
  icon,
  ...props
}: { icon: ReactNode } & ComponentProps<typeof Input>) {
  return (
    <div className="flex w-full items-center gap-5 rounded-md border pl-3 pr-2 has-[:focus]:border-border">
      {icon}
      <Input
        {...props}
        className={cn(
          'text-md border-none px-0 focus-visible:ring-0 focus-visible:ring-offset-0',
          props.className,
        )}
      />
    </div>
  );
}
