import { useEffect } from 'react';

import { useStartPageRestaurants } from '@/actions/restaurant/get-start-page-restaurants';
import { useTranslation } from '@/i18n/client';
import {
  Eat_RestaurantFragment,
  Eat_Restaurant_DiningMode,
} from '@/lib/__generated__/graphql';
import {
  inAppCanNavigateBack,
  inAppNavigateBack,
  inAppTrackEvent,
} from '@/lib/app-integration';
import { Link } from '@/lib/navigation';
import { useMainStore } from '@/stores/main-store';

import { Radar } from '../1-restaurant/components/radar';

export function StartPage() {
  const { t } = useTranslation();

  const { loading, restaurants } = useStartPageRestaurants([
    'camino-bar',
    'nooba',
    'burgers',
    'il-paun',
  ]);

  const setCanNavigateBackToStartPage = useMainStore(
    (state) => state.setCanNavigateBackToStartPage,
  );

  useEffect(() => {
    inAppTrackEvent({ name: 'eat_delivery-and-take-away-landing_page-view' });
    setCanNavigateBackToStartPage(true);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="relative pb-36 pt-10 text-center font-sans">
        <img
          src="/home-screen/landing-banner-tall.webp"
          alt="LAAX EAT"
          className="absolute inset-0 z-0 h-full w-full object-cover"
        />
        <div className="absolute inset-0 z-0 h-full w-full bg-black opacity-15" />
        <div className="relative z-10 mr-5 flex flex-col items-end text-white">
          <Radar className="mb-4 h-12" style={{ fill: 'white' }} />
          <h1 className="mb-2 mr-[-3px] text-right text-4xl">
            <span className="tracking-tightest">LAAX</span>{' '}
            <span className="font-bold">EAT</span>
          </h1>
          <h2 className="text-right text-2xl">
            Delivery &<br /> Take-away
          </h2>
        </div>
        {inAppCanNavigateBack && (
          <button
            className="absolute left-5 top-5 z-20 mt-4 rounded border border-white px-4 py-2 font-bold text-white transition-all duration-200 active:opacity-50"
            onClick={inAppNavigateBack}
          >
            {t(
              'Delivery & Take-Away - Landing page - Back button label',
              'Back',
            )}
          </button>
        )}
      </div>
      <div className="grid gap-8 p-6">
        <p className="text-md font-light">
          {t(
            'Delivery & Take-Away - Landing page - Service description',
            'Delicious food delivered direct in the Riders Hotel, Signina Hotel, and rocksresort.',
          )}
        </p>
        {loading ? (
          <div className="mt-10 flex items-center justify-center">
            <div className="flex items-center justify-center">
              <div className="h-16 w-16 animate-spin rounded-full border-b-2 border-t-2 border-black"></div>
            </div>
          </div>
        ) : (
          <div className="grid gap-6">
            {restaurants?.map((restaurant) => (
              <RestaurantCard key={restaurant.id} restaurant={restaurant} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function RestaurantCard(props: { restaurant: Eat_RestaurantFragment }) {
  return (
    <Link
      to={`/restaurant/${props.restaurant.id}`}
      className="max-w-sm overflow-hidden rounded text-left shadow-lg"
    >
      <img
        src={props.restaurant.imageUrls[0] ?? props.restaurant.heroImageUrl}
        alt={props.restaurant.name}
        className="h-48 w-full object-cover"
      />
      <div className="px-6 py-4">
        <h3 className="mb-2 text-2xl font-light">{props.restaurant.name}</h3>
        <div className="mb-2 inline-block rounded bg-gray-100 px-3 py-1 text-sm font-semibold">
          {getDiningModeText(props.restaurant.diningMode)}
        </div>
        <p className="mb-2 text-sm font-light">
          {props.restaurant.shortDescription}
        </p>
      </div>
    </Link>
  );
}

function getDiningModeText(diningMode: Eat_Restaurant_DiningMode) {
  const { t } = useTranslation();

  switch (diningMode) {
    case 'DELIVERY':
      return t('Dining mode - Delivery', 'Delivery & Take-away');
    case 'TABLE':
      return t('Dining mode - Table', 'Table service');
    case 'TAKE_AWAY':
      return t('Dining mode - Take-away', 'Take-away');
  }
}
