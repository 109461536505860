import { toast } from 'sonner';

import { createDeliveryOrder } from '@/actions/order/create-delivery-order';
import { createTableOrder } from '@/actions/order/create-table-order';
import { createTakeAwayOrder } from '@/actions/order/create-take-away-order';
import { Order } from '@/actions/order/order-types';
import { startAnonWebPayment } from '@/actions/payment/start-anon-web-payment';
import { startUserWebPayment } from '@/actions/payment/start-user-web-payment';

import {
  Eat_Order_CreateTableOrder_Input,
  SupportedPaymentType,
} from './__generated__/graphql';
import { getAnonId, getClientType, neverHappens } from './utils';

export async function createOrderForDiningMode(
  {
    diningMode,
    input: { tableNumber, contactNumberAsE164, ...input },
  }: {
    diningMode: 'DELIVERY' | 'TABLE' | 'TAKE_AWAY';
    input: Omit<
      Eat_Order_CreateTableOrder_Input,
      'tableNumber' | 'contactNumberAsE164'
    > & {
      tableNumber?: string | null;
      contactNumberAsE164?: string | null;
      deliveryChargeRappen?: number | null;
      deliveryLocationId?: string | null;
      deliverySubLocationId?: string | null;
    };
  },
  t: (key: string, defaultValue: string) => string,
): Promise<Order | undefined> {
  switch (diningMode) {
    case 'DELIVERY': {
      if (contactNumberAsE164 == null) {
        toast.error(
          t('Toast - Phone number required', 'A phone number is required'),
        );
        return;
      }

      return input.deliveryLocationId && input.deliverySubLocationId
        ? createDeliveryOrder({
            anonId: getAnonId(),
            input: {
              restaurantId: input.restaurantId,

              entriesTotalRappen: input.entriesTotalRappen,
              tipRappen: input.tipRappen,

              nameOnReceipt: input.nameOnReceipt,
              contactNumberAsE164,

              commentOnReceipt: input.commentOnReceipt,

              deliveryChargeRappen: input.deliveryChargeRappen ?? 0,
              deliveryLocationId: input.deliveryLocationId,
              deliverySubLocationId: input.deliverySubLocationId,

              clientType: getClientType(),
            },
          })
        : createTakeAwayOrder({
            anonId: getAnonId(),
            input: {
              restaurantId: input.restaurantId,

              entriesTotalRappen: input.entriesTotalRappen,
              tipRappen: input.tipRappen,

              nameOnReceipt: input.nameOnReceipt,
              contactNumberAsE164,

              commentOnReceipt: input.commentOnReceipt,

              clientType: getClientType(),
            },
          });
    }

    case 'TABLE': {
      if (tableNumber == null) {
        toast.error(
          t('Toast - Table number required', 'A table number is required'),
        );
        return;
      }
      return createTableOrder({
        anonId: getAnonId(),
        input: {
          restaurantId: input.restaurantId,

          entriesTotalRappen: input.entriesTotalRappen,
          tipRappen: input.tipRappen,

          nameOnReceipt: input.nameOnReceipt,
          tableNumber,

          commentOnReceipt: input.commentOnReceipt,

          clientType: getClientType(),
        },
      });
    }

    case 'TAKE_AWAY': {
      if (contactNumberAsE164 == null) {
        toast.error(
          t('Toast - Phone number required', 'A phone number is required'),
        );
        return;
      }
      return createTakeAwayOrder({
        anonId: getAnonId(),
        input: {
          restaurantId: input.restaurantId,

          entriesTotalRappen: input.entriesTotalRappen,
          tipRappen: input.tipRappen,

          nameOnReceipt: input.nameOnReceipt,
          contactNumberAsE164,

          commentOnReceipt: input.commentOnReceipt,

          clientType: getClientType(),
        },
      });
    }
    default: {
      neverHappens(diningMode);
    }
  }
}

export async function initializeWebPayment({
  isAuthenticated,
  restaurantId,
  orderId,
  saveNewCreditCard,
  paymentTypeCode,
}: {
  orderId: string;
  restaurantId: string;
  saveNewCreditCard: boolean;
  paymentTypeCode: SupportedPaymentType;
  isAuthenticated: boolean;
}) {
  let paymentPageUrl;

  if (isAuthenticated) {
    const response = await startUserWebPayment({
      input: {
        orderId: orderId,
        paymentType: paymentTypeCode,
        returnUrl: `${origin}/${restaurantId}/checkout/pending?orderId=${orderId}`,
        storePaymentMeans: saveNewCreditCard,
      },
    });
    paymentPageUrl = response.paymentPageUrl;
  } else {
    const response = await startAnonWebPayment({
      input: {
        anonId: getAnonId(),
        orderId: orderId,
        paymentType: paymentTypeCode, // FIXME: Rename on backend to paymentTypeCode
        returnUrl: `${origin}/${restaurantId}/checkout/pending?orderId=${orderId}`,
      },
    });
    paymentPageUrl = response.paymentPageUrl;
  }

  if (!paymentPageUrl) {
    throw new Error('Failed to start web checkout');
  }

  window.location.href = paymentPageUrl;
}
