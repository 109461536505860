declare global {
  interface Window {
    appFeatureFlags: unknown;
  }
}

type FeatureFlag = 'save-twint-payment';

export function isFeatureFlagEnabled(featureFlag: FeatureFlag) {
  // App populates this value on init
  // When/if Braze is implemented in webview, the values should be fetched directly from there.
  return (
    Array.isArray(window.appFeatureFlags) &&
    window.appFeatureFlags.some((flag) => featureFlag === flag)
  );
}
