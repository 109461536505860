import { DeliveryOrder } from '@/actions/order/order-types';
import { graphql } from '@/lib/__generated__';
import { MutationEat_Order_CreateDeliveryOrderArgs } from '@/lib/__generated__/graphql';
import { apolloClient } from '@/lib/apollo/apollo-client';

export async function createDeliveryOrder(
  variables: MutationEat_Order_CreateDeliveryOrderArgs,
): Promise<DeliveryOrder | undefined> {
  const { data } = await apolloClient.mutate({
    mutation: graphql(`
      mutation Eat_Order_CreateDeliveryOrder(
        $input: Eat_Order_CreateDeliveryOrder_Input!
        $anonId: ID
      ) {
        Eat_Order_CreateDeliveryOrder(input: $input, anonId: $anonId) {
          order {
            ...Eat_Order_Delivery
          }
        }
      }
    `),
    variables,
  });

  return data?.Eat_Order_CreateDeliveryOrder.order;
}
