import { useTranslation } from '@/i18n/client';
import { Eat_Menu_Allergen } from '@/lib/__generated__/graphql';

export function AllergenDetails(props: {
  allergens: Eat_Menu_Allergen[] | null | undefined;
}) {
  const { t } = useTranslation();

  if (!props.allergens || props.allergens.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-row flex-wrap py-2">
      <p className="pr-2 text-muted-foreground">
        {t('Allergens - Detail - Contains', 'Contains')}
        {':'}
      </p>
      {props.allergens.map((allergen, index) => (
        <div
          key={allergen.code}
          className="flex flex-row items-center gap-0.5 pr-1"
        >
          <AllergenIcon code={allergen.code} />
          <span className="text-muted-foreground">
            {allergen.text}
            {index < props.allergens!.length - 1 ? ',' : ''}
          </span>
        </div>
      ))}
    </div>
  );
}

export function AllergenIcons(props: {
  allergens: Eat_Menu_Allergen[] | null | undefined;
}) {
  if (!props.allergens || props.allergens.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-wrap gap-1">
      {props.allergens.map((allergen) => (
        <AllergenIcon key={allergen.code} code={allergen.code} />
      ))}
    </div>
  );
}

function AllergenIcon(props: { code: string }) {
  return <img src={`/icons/allergens/${props.code}.svg`} className="h-4 w-4" />;
}
