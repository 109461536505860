import { useQuery } from '@apollo/client';

import { graphql } from '@/lib/__generated__/gql';

export function useStartPageRestaurants(
  filterByRestaurantIdsInOrder: string[] = [],
) {
  const { loading, error, data } = useQuery(
    graphql(`
      query ListRestaurants($input: Eat_Restaurant_ListRestaurants_Input!) {
        Eat_Restaurant_ListRestaurants(input: $input) {
          restaurants {
            ...Eat_Restaurant
          }
        }
      }
    `),
    {
      variables: { input: {} },
    },
  );

  return {
    loading,
    error,
    restaurants: data?.Eat_Restaurant_ListRestaurants.restaurants
      .filter((restaurant) =>
        filterByRestaurantIdsInOrder.includes(restaurant.id),
      )
      .sort((a, b) => {
        return (
          filterByRestaurantIdsInOrder.indexOf(a.id) -
          filterByRestaurantIdsInOrder.indexOf(b.id)
        );
      }),
  };
}
