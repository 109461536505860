import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { ListEntry } from '@/components/common/list-entry';
import { Eat_CartFragment, Eat_Menu_Entry } from '@/lib/__generated__/graphql';
import { cn, rappenToFrancs } from '@/lib/utils';

export function MenuSection(props: {
  sectionId: string;
  sectionName: string;
  entries: Eat_Menu_Entry[];
  restaurantId: string;
  cart: Eat_CartFragment;
  withHeader: boolean;
}) {
  const pathname = useLocation().pathname;
  const { t } = useTranslation();

  // Filter out entries that are not enabled and not in the cart
  const visibleEntries = props.entries.filter(
    (entry) =>
      entry.enabled ||
      props.cart.entries.some((cartEntry) => cartEntry.sku === entry.sku),
  );

  return (
    <div
      id={props.sectionId}
      className="divide-y-2 divide-muted border-b-2 border-b-muted"
    >
      {props.withHeader && (
        <div className="bg-muted px-5 py-3">
          <p className="text-lg font-medium">{props.sectionName}</p>
        </div>
      )}

      {visibleEntries.map((entry) => {
        const quantityInCart = props.cart.entries
          .filter((cartEntry) => cartEntry.sku === entry.sku)
          .reduce((acc, cartEntry) => acc + cartEntry.quantity, 0);

        return (
          <NavLink
            key={entry.sku}
            className={({ isPending }) =>
              cn(
                'block transition-transform duration-200',
                isPending && 'animate-press-in',
              )
            }
            to={`${pathname}/entry/${entry.sku}`}
            preventScrollReset // FIXME: Why is this here?
          >
            <ListEntry
              {...entry}
              className="px-5"
              name={
                <>
                  <span
                    className={cn(
                      'text-laaxred',
                      !entry.enabled && 'opacity-50',
                    )}
                  >
                    {quantityInCart ? `${quantityInCart}× ` : ''}
                  </span>
                  {entry.name}
                </>
              }
              priceText={
                entry.__typename === 'Eat_Menu_Deal'
                  ? `${rappenToFrancs(entry.basePriceRappen || entry.minimumPrice)} CHF ${t('Menu - Base Price', '(base price)')}`
                  : rappenToFrancs(entry.priceRappen) + ' CHF'
              }
            />
          </NavLink>
        );
      })}
    </div>
  );
}
