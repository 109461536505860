import { IconInput } from '@/app/4-order-details/components/icon-input';
import { TableIcon } from '@/components/common/icons/table-icon';
import { useTranslation } from '@/i18n/client';
import { useMainStore } from '@/stores/main-store';

type Props = {
  validateTableNumber: () => boolean;
};

export function TableNumberInput(props: Props) {
  const { t } = useTranslation();

  const { tableNumber, setTableNumber } = useMainStore((state) => ({
    tableNumber: state.tableNumber,
    setTableNumber: state.setTableNumber,
  }));

  return (
    <IconInput
      icon={<TableIcon className="mr-[-0.3rem] size-8" />}
      required
      inputMode="numeric"
      value={tableNumber ?? ''}
      onChange={(e) => setTableNumber(e.target.value)}
      onBlur={props.validateTableNumber}
      placeholder={t('Details - Table number - Placeholder', 'Table number*')}
    />
  );
}
